


































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import AreaSelect from '@/components/area-select.vue'
import OrderLogistics from '@/components/order/order-logistics.vue'
import {
    apiOrderDetail,
    apiOrderChangeExpressPrice,
    apiOrderRemarks,
    apiOrderConfirm,
    apiOrderCancel,
    apiOrderChangeGoodsPrice,
    apiOrderAddressEdit,
    apiCommunityAll,
    apiOrderReportDetailList
} from '@/api/order/order'
import {
    apiReportDetailList, // 列表
    apiPauseDelivery, // 暂停配送
    apiStartDelivery, // 开启配送
    apiChangeDeliveryRecord // 更改配送规则
} from '@/api/delivery'
import { apiSelffetchVerification } from '@/api/application/selffetch'
import { timeFormat } from '@/utils/util'
// import el from 'element-ui/src/locale/lang/el'

@Component({
    components: {
        LsDialog,
        AreaSelect,
        OrderLogistics
    }
})
export default class OrderDetail extends Vue {
    // S Data
    $refs!: { dateForm: any }

    pickerOptions0 = {
        disabledDate(time:any) {
            return false
            return time.getTime() < Date.now() - 8.64e6 // 如果没有后面的-8.64e6就是不可以选择今天的
        }
      }

    // 订单详情ID
    id: any = 0
    lock = false
    dateVisible = false // 更改配送周期的窗口
    weekArray = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'] // 每周选择
	weekIndex = [ 1, 2, 3, 4, 5, 6, 7] // 每周选中
    otherDayArray = ['不间隔', '间隔1天', '间隔2天', '间隔3天', '间隔4天', '间隔5天', '间隔6天', '间隔7天'] // 隔日选择
    otherDayIndex = 0 // 隔日选中

    formLabelWidth = '120px' // 退订弹窗表单中label的宽
    offVisible = false // 暂停配送或开启配送

    taskEditType = 0
    // 退订表单数据
    form: any = {
        refund_reason: [], // 退订原因
        stop_date: '', // 退订日期
        amount: '', // 退订金额
    }
    // 暂停或配送日期
    offForm: any = {
        date: '',
        order_id: '',
        order_goods_id: ''
    }
     // 暂停或开启配送验证
    offFormRules = {
        date: [
            {
                required: true,
                message: '请选择时间',
                trigger: ['blur', 'change']
            }
        ]
    }
   
    // 订单数据
    orderData: any = {
        admin_order_btn: {
            remark_btn: 1,
            cancel_btn: 0,
            confirm_btn: 0,
            logistics_btn: 0,
            refund_btn: 0,
            address_btn: 1,
            price_btn: 1
        }
    }

    // 暂停或开启配送
     // 暂停配送
     suspendDelivery(row: any) {
        this.offVisible = true
        this.offForm.date = ""
        this.offForm.order_id = row.order_id
        this.offForm.order_goods_id = row.order_goods_id
        this.taskEditType = 0

    }
    // 开启配送
    openDelivery(row: any) {
        this.offVisible = true
        this.offForm.date = ""
        this.offForm.order_id = row.order_id
        this.offForm.order_goods_id = row.order_goods_id
        this.taskEditType = 1

     }
     
    taskEdit(row:any) {
        if(!this.offForm.date) {
            this.$message.error("请选择时间");
            return;
        }
        this.lock = true;
        if(this.taskEditType == 1) {
        // 开启
            apiStartDelivery({
                ...this.offForm
            }).then(res => {
                this.closeDialog()
                this.getReportDetailList()
                this.lock = false;
            })
        }else {
        // 关闭
            apiPauseDelivery({
                ...this.offForm
            }).then(res => {
                this.closeDialog()
                this.getReportDetailList()
                this.lock = false;
            })
        }

    }

    address: any = {
        contact: "",
        mobile: "",
        community_id: "",
        address: '' //必填	varchar	详细地址
    }

    reportDetailList:any =[] // 配送列表
    dialogFormVisible: boolean = false

    // 商家备注
    remarks: String = ''

    // 运费更改
    express_price: String = ''

    // 商品价格
    goods_price: String = ''

    // 小区列表
    communityList: any = []

      // 更改配送周期
    dateForm: any = {
        order_id: '', // 订单id
        order_goods_id: '', // 订单商品id
        delivery_cycle_type: '1', // 配送周期类型(1、自定义；2、隔日)
        delivery_cycle_detail: '1,2,3,4,5,6,7', // 配送周期具体详情（每周：1，2，3，4；隔日：0为不间隔，其他数字为间隔天数）
        every_num: '1', // 每次份数
        delivery_date: '', // 开始配送日期
    }

      // 更改配送日期验证
    dateFormRules = {
        delivery_date: [
            {
                required: true,
                message: '请选择起送时间',
                trigger: ['blur', 'change']
            }
        ]
    }
    // E Data

    // S Methods

     // 更改配送规格
    dateDelivery(row:any) {
        console.log("2193128", row)
        this.dateForm.order_id = row.order_id
        this.dateForm.order_goods_id = row.order_goods_id
        this.dateVisible = true
    }
    // 确认更改配送规格
    dateEdit() {
        if(this.dateForm.delivery_cycle_type == 1) {
            this.dateForm.delivery_cycle_detail = this.weekIndex.join(",")
        }else {
            this.dateForm.delivery_cycle_detail = this.otherDayIndex + 1
        }
        this.$refs.dateForm.validate((valid: boolean) => {
            if (valid) {
                this.lock = true
                apiChangeDeliveryRecord({
                    ...this.dateForm,
                }).then(res => {
                    console.log("res", res)
                    this.closeDialog()
                    this.lock = false
                })
            } else {
                return false
            }
        })
        
    }
    // 隔日选择
    otherDayChange(item:any, index:any) {
        this.otherDayIndex = index;
    }

    weekChange(item:any, indexTemp:any) {
        let weekIndex = this.weekIndex;
        let index = weekIndex.findIndex(i => i == indexTemp);
        if (index == -1) { //增加自定义星期选择
            weekIndex.push(indexTemp);
        } else {
            if (weekIndex.length > 0) {
                if (weekIndex.length == 1) {
                    this.$message.warning("不能一个都不选哦")
                    return
                }
                weekIndex.splice(index, 1);
            }
        }
        this.weekIndex = weekIndex
        console.log(weekIndex, '配送周期')
    }
    // 关闭退订弹窗
    closeDialog() {
        this.weekArray = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'] // 每周选择
        this.weekIndex = [ 1, 2, 3, 4, 5, 6, 7] // 每周选中
        this.otherDayArray = ['不间隔', '间隔1天', '间隔2天'] // 隔日选择
        this.otherDayIndex = 0 // 隔日选中
        this.dateForm = {
            order_id: '', // 订单id
            order_goods_id: '', // 订单商品id
            delivery_cycle_type: '1', // 配送周期类型(1、自定义；2、隔日)
            delivery_cycle_detail: '1,2,3,4,5,6,7', // 配送周期具体详情（每周：1，2，3，4；隔日：0为不间隔，其他数字为间隔天数）
            every_num: '1', // 每次份数
            delivery_date: '', // 开始配送日期
        }
        this.dateVisible = false
        this.offVisible = false
        
    }
    


    // 获取配送列表
    getReportDetailList() {
        apiOrderReportDetailList({order_id: this.id}).then(res => {
            this.reportDetailList = res;
        })
    }
    // 获取小区列表
    getCommunityAll() {
        apiCommunityAll({}).then(res => {
            this.communityList = res
        })
    }
    // 获取订单详情
    getOrderDetail() {
        apiOrderDetail({ id: this.id }).then(res => {
            this.orderData = res;
            console.log('res', res)
        })
    }

    // 取消订单
    orderCancel() {
        apiOrderCancel({ id: this.id }).then(res => {
            this.getOrderDetail()
        })
    }

    // 提货核销
    selffetch() {
        apiSelffetchVerification({ id: this.id }).then(() => {
            this.getOrderDetail()
        })
    }
    // 打开订单地址修改弹窗
    openEdit() {
        let i = this.communityList.findIndex((item:any) => item.name == this.orderData.community)
        
        this.address = {
            contact: this.orderData.contact,
            mobile: this.orderData.mobile,
            community_id: this.communityList[i].id,
            address:  this.orderData.address 
        }
    }
    // 订单地址修改
    orderAddressSet() {
        console.log("111", this.address)
        apiOrderAddressEdit({
            id: this.id,
            ...this.address
        }).then(res => {
            this.getOrderDetail();
            this.getReportDetailList();
        })
    }

    // 确认收货
    orderConfirm() {
        apiOrderConfirm({ id: this.id }).then(res => {
            this.getOrderDetail()
        })
    }

    // 商家备注
    postOrderRemarks() {
        apiOrderRemarks({
            id: [this.id],
            order_remarks: this.remarks
        }).then(res => {
            this.getOrderDetail()
        })
    }

    // 修改商品价格
    orderChangeGoodsPrice(id: Number) {
        if (this.goods_price == '') {
            return this.$message.error('请输入价格')
        }
        apiOrderChangeGoodsPrice({
            order_goods_id: id,
            change_price: this.goods_price
        }).then(res => {
            this.getOrderDetail()
            this.goods_price = ''
        })
    }

    // 修改运费
    orderChangeExpress() {
        if (this.express_price == '') {
            return this.$message.error('请输入运费！')
        }
        apiOrderChangeExpressPrice({
            id: this.id,
            express_price: this.express_price
        }).then(res => {
            this.getOrderDetail()
        })
    }

    // 商品信息的底部结算信息
    getSummaries(param: any) {
        console.log('param', param)
        const { columns, data } = param
        const sums: any = []
        columns.forEach((column: any, index: any) => {
            if (index === 0) {
                sums[0] = '总价'
                return
            }
            console.log('data', data)
            const values = data.map((item: any) => {
                if (index == 2 || index == 3) {
                    if(item.goods_item) {
                        return Number(item.goods_item[column.property])
                    }else {
                        return 0
                    }
                }
                return Number(item[column.property])
            })
            console.log('valus', values)
            if (!values.every((value: any) => isNaN(value))) {
                if (index == 1) {
                    return
                }

                sums[index] = values.reduce((prev: any, curr: any) => {
                    const value = Number(curr)
                    if (!isNaN(value)) {
                        return prev + curr
                    }
                    return prev
                }, 0)
                if (index !== 4) {
                    sums[index] = '¥' + sums[index]
                }
                if (index == 7) {
                    sums[index] = '-' + sums[index]
                }
                if (index == 8) {
                    sums[index] = '¥' + this.orderData.total_goods_pay_price
                }
            }
        })

        return sums
    }


    toUserDetail() {
        this.$router.push({
            path: '/user/user_details',
            query: {
                id: this.orderData.user_id
            }
        })
    }

    // E Methods

    created() {
        this.getCommunityAll()
        this.id = this.$route.query.id
        this.id && this.getOrderDetail()
        this.id && this.getReportDetailList()

    }
}
